import React, { lazy, Suspense } from "react";

const Main = lazy(() => import("@components/main"));

import { AppLayout } from "@layouts";
import { LoadingSpinner } from "@components/LoadingSpinner";
const Page: React.FC = () => {
    return (
        <AppLayout className="home">
            <div>
                <section className="splash">
                    <div className="content">
                        <Suspense fallback={<LoadingSpinner></LoadingSpinner>}>
                            <Main />
                        </Suspense>
                    </div>

                    <div className="copyright align-text-bottom">
                        <a href="https://www.stottweddings.com/" target="_blank" rel="noopener">
                            Ⓒ Image @StottWeddings
                        </a>
                    </div>
                </section>
            </div>
        </AppLayout>
    );
};

export default Page;
